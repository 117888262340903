import axios, { AxiosInstance } from "axios";
import { loadding } from "./loadding";
import { app as Vue } from "@/main";
import { UserModule } from "@/model/user";
import { has_id, Id } from "@/types/global";
// export const baseUrl = 'http://192.168.3.214:8000'
export const baseUrl = "https://api.xiyuebiaoju.com";
// export const baseUrl = "http://test.xiyuebiaoju.com";
const server = axios.create({
  //   baseURL: process.env.VUE_APP_API,
  baseURL: baseUrl,
  timeout: 10000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

server.interceptors.request.use(
  (config) => {
    loadding.showLoading();
    config.headers.AUTHORIZATION = `token=${UserModule.token}`;
    return config;
  },
  (err) => {
    loadding.hideLoading();
    return Promise.reject(err);
  }
);
server.interceptors.response.use(
  ({ data, status }) => {
    loadding.hideLoading();
    if (data.code) {
      if (data.code == 401) {
        UserModule.logout();
        Vue.$router.push("/login");
      }
      if(data.code ==400){
        Vue.$message.error(data.msg ? data.msg : "系统错误");
      }
      if (data.code != 200) {
        return Promise.reject(data);
      }
      if (
        data.msg === "没有操作权限" ||
        data.msg === "账号授权已失效,请重新登录"
      ) {
        UserModule.logout();
        Vue.$router.push("/login");
      }
      if (data.msg != "") {
        Vue.$message.success(data.msg ? data.msg : "请求成功");
      }
      return Promise.resolve(data.data);
    }
    if (status === 204) {
      Vue.$message.success("删除成功");
    }
    return data;
  },
  (err) => {
    // console.log("121212");
    console.log(err);
    loadding.hideLoading();
    if (err?.response?.status === 401) {
      Vue.$message.error(`401错误`);
      return Promise.reject(err);
    }
    if (err?.response?.status === 403) {
      UserModule.logout();
      Vue.$router.push("/login");
      Vue.$message.error("登录过期");
      return Promise.reject(err);
    }
    Vue.$message.error(`请求错误`);
    return Promise.reject(err);
  }
);

export abstract class Http {
  protected readonly uri: string;
  protected readonly server: AxiosInstance = server;

  constructor(uri: string) {
    this.uri = uri;
  }

  protected post<T = unknown>(data: any, uri = this.uri): Promise<T> {
    return this.server.post(uri, data);
  }

  protected get<T = unknown>(params?: any, uri = this.uri): Promise<T> {
    return this.server.get(uri, { params });
  }

  protected async delete<T = unknown>(
    id: number,
    confirm_str = "是否删除?"
  ): Promise<T> {
    await Vue.$confirm(confirm_str);
    return this.server.delete(`${this.uri}${id}/`);
  }

  protected put<T = unknown>(data: any, uri = this.uri): Promise<T> {
    return this.server.put(uri, data);
  }
  protected patch<T = unknown>(data: any, uri = this.uri): Promise<T> {
    return this.server.patch(uri, data);
  }
}
