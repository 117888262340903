import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import { router, asyncRoutes } from './router'
import store from './store'
import { UserModule } from '@/model/user';
import { filterAsyncRoutes } from './router/asyncRouter'
import '@/assets/css/reset_ele.scss'
import "@/assets/css/reset.css"
import "@/assets/css/iconfont.css"
import VueAMap from "vue-amap"
import Print from 'vue-print-nb'
Vue.config.productionTip = false

Vue.use(Element)
Vue.use(VueAMap)
Vue.use(Print)
VueAMap.initAMapApiLoader({
  key: '89bf01f52b9f96eff3cba83e20c6cc06',
  plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder'],
  v: '1.4.4',
  uiVersion: '1.0'
})
Vue.filter('emptyIfFalsy', function(value:any) {
    return value ? value : '--';
  });

router.beforeEach(async(to:any, _, next) => {
	if (to.meta.without_auth === true) {
		return next()
	} else {
		if (UserModule.token !== null) {
			const hasRoles = store.state.routes && (store.state.routes as any).length > 0  
            console.log(store.state.routes,'测试')
            if (hasRoles) {
                next()
            } else {
                try {
                    // 获取用户角色
                    const roles = UserModule.auth.map((item:any)=>item.id)
                    // 通过用户角色，获取到角色路由表
                    var accessRoutes:any = filterAsyncRoutes(asyncRoutes,roles);
                    // 动态添加路由到router内
                    (router as any).matcher.addRoutes(accessRoutes)
                    store.commit('saveRoute',accessRoutes)
                    next({ ...to, replace: true })
                } catch (error) {
                    // 清除用户登录信息后，回跳到登录页去
                    UserModule.logout()
                    store.state.routes = null
                    next(`/login`)
                    // NProgress.done()
                }
            }
		} else {
			UserModule.logout()
			return next('/login')
		}
	}
})


export const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
