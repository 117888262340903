import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Rview from "@/components/routerView/index.vue";

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: "/login",
    meta: {
      title: "登录",
      without_auth: true,
    },
    component: () => import("@/views/login/index.vue"),
  },
];

export const asyncRoutes = [
  {
    path: "/",
    redirect: "/home",
    component: () => import("@/components/global/index.vue"),
    meta: {
      roles: [1],
    },
    children: [
      {
        path: "home",
        name: "home",
        meta: {
          title: "首页",
          cache: false,
          roles: [1,5],
        },
        component: () => import("@/views/home/index.vue"),
      },
      {
        path: "store",
        name: "store",
        meta: {
          title: "门店管理",
          cache: false,
          roles: [1],
        },
        redirect: "/store/",
        component: Rview,
        children: [
          {
            path: "",
            meta: {
              title: "门店列表",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/store/index.vue"),
          },
          {	
            path: 'edit',
            meta:{
              title:'新增门店',
              titleEdit:'编辑门店',
              cache: false,
              roles:[1]
              // without_auth: true
            },
            component: () => import('@/views/store/edit/index.vue')
          }
        ],
      },
      {
        path: "banner",
        name: "banner",
        meta: {
          title: "轮播图管理",
          cache: false,
          roles: [1],
        },
        redirect: "/banner/",
        component: Rview,
        children: [
          {
            path: "",
            meta: {
              title: "轮播图列表",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/banner/index.vue"),
          },
          {	
            path: 'edit',
            meta:{
              title:'新增轮播图',
              titleEdit:'编辑轮播图',
              cache: false,
              roles:[1]
              // without_auth: true
            },
            component: () => import('@/views/banner/edit/index.vue')
          }
        ],
      },
      {
        path: "goods",
        name: "goods",
        meta: {
          title: "商品管理",
          cache: false,
          roles: [1],
        },
        redirect: "/goods/",
        component: Rview,
        children: [
          {
            path: "",
            meta: {
              title: "商品列表",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/goods/index.vue"),
          },
         
          {	
            path: 'edit',
            meta:{
              title:'添加商品',
              titleEdit:'编辑商品',
              cache: false,
              roles:[1]
              // without_auth: true
            },
            component: () => import('@/views/goods/edit/index.vue')
          },
          
        ],
      },
      {
        path: "category",
        name: "category",
        meta: {
          title: "分类管理",
          cache: false,
          roles: [1],
        },
        redirect: "/category/goods/",
        component: Rview,
        children:[
          {
            path: "",
            meta: {
              title: "分类列表",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/category/goods/index.vue"),
          },
          {	
            path: 'edit',
            meta:{
              title:'添加分类',
              titleEdit:'编辑分类',
              cache: false,
              roles:[1]
              // without_auth: true
            },
            component: () => import('@/views/category/goods/edit/index.vue')
          }
        ]
      },
      {
        path: "order",
        name: "order",
        meta: {
          title: "订单管理",
          cache: false,
          roles: [1],
        },
        redirect: "/order/",
        component: Rview,
        children: [
          {
            path: "",
            meta: {
              title: "订单查询",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/order/index.vue"),
          },
          {
            path: "edit",
            meta: {
              title: "订单详情",
              titleEdit:'订单详情',
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/order/edit/index.vue"),
          },
        ],
      },
      {
        path: "user",
        name: "user",
        meta: {
          title: "用户管理",
          cache: false,
          roles: [1],
        },
        redirect: "/user/",
        component: Rview,
        children: [
          {
            path: "",
            meta: {
              title: "用户列表",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/user/index.vue"),
          },
          {
            path: "edit",
            meta: {
              title: "添加用户",
              titleEdit:'编辑用户',
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/user/edit/index.vue"),
          }
        ],
      },
      {
        path: "auth",
        meta: {
          title: "账号管理",
          cache: false,
          roles: [1],
          // without_auth: true
        },
        redirect: "/auth/admin",
        component: Rview,
        children: [
          {
            path: "admin",
            meta: {
              title: "",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: Rview,
            children: [
              {
                path: "",
                meta: {
                  title: "员工管理",
                  cache: false,
                  roles: [1],
                  // without_auth: true
                },
                component: () => import("@/views/auth/admin/index.vue"),
              },
              {
                path: "edit",
                meta: {
                  title: "添加管理员",
                  titleEdit: "编辑管理员",
                  cache: false,
                  roles: [1],
                  // without_auth: true
                },
                component: () => import("@/views/auth/admin/edit/index.vue"),
              },
              {
                path: "",
                meta: {
                  title: "角色管理",
                  cache: false,
                  roles: [1],
                  // without_auth: true
                },
                component: () => import("@/views/auth/role/index.vue"),
              },
              {
                path: "edit",
                meta: {
                  title: "添加角色",
                  titleEdit: "编辑角色",
                  cache: false,
                  roles: [1],
                  // without_auth: true
                },
                component: () => import("@/views/auth/role/edit/index.vue"),
              },
            ],
          },
          {
            path: "role",
            meta: {
              title: "角色管理",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: Rview,
            children: [
              {
                path: "",
                meta: {
                  title: "角色列表",
                  cache: false,
                  roles: [1],
                  // without_auth: true
                },
                component: () => import("@/views/auth/role/index.vue"),
              },
              {
                path: "edit",
                meta: {
                  title: "添加角色",
                  titleEdit: "编辑角色",
                  cache: false,
                  roles: [1],
                  // without_auth: true
                },
                component: () => import("@/views/auth/role/edit/index.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "partner",
        meta: {
          title: "合伙人管理",
          cache: false,
          roles: [1],
          // without_auth: true
        },
        redirect: "/partner",
        component: Rview,
        children: [
          {
            path: "",
            meta: {
              title: "合伙人管理",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/partner/index.vue"),
          },
          {
            path: "edit",
            meta: {
              title: "添加合伙人",
              titleEdit: "编辑合伙人",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/partner/edit/index.vue"),
          },
          {
            path: "",
            meta: {
              title: "角色管理",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/auth/role/index.vue"),
          },
          {
            path: "edit",
            meta: {
              title: "添加角色",
              titleEdit: "编辑角色",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/auth/role/edit/index.vue"),
          },
        ],
      },
      {
        path: "amount",
        name: "amount",
        meta: {
          title: "财务设置",
          cache: false,
          roles: [1],
        },
        redirect: "/amount/",
        component: Rview,
        children: [
          {
            path: "",
            meta: {
              title: "财务列表",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/amount/index.vue"),
          },
        ],
      },
      {
        path: "meal_record",
        name: "meal_record",
        meta: {
          title: "充值管理",
          cache: false,
          roles: [1],
        },
        redirect: "/meal_record/",
        component: Rview,
        children: [
          {
            path: "",
            meta: {
              title: "充值管理",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/meal_record/index.vue"),
          },
        ],
      },
      {
        path: "settings",
        name: "settings",
        meta: {
          title: "系统设置",
          cache: false,
          roles: [1],
        },
        redirect: "/settings/",
        component: Rview,
        children: [
          {
            path: "",
            meta: {
              title: "系统设置",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/settings/index.vue"),
          },
          {
            path: "edit",
            meta: {
              title:'出入库配置',
              titleEdit:'编辑出入库配置',
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/settings/edit/index.vue"),
          },
        ],
      },
      {
        path: "meal",
        name: "meal",
        meta: {
          title: "储值套餐",
          cache: false,
          roles: [1],
        },
        redirect: "/meal/",
        component: Rview,
        children: [
          {
            path: "",
            meta: {
              title: "套餐列表",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/meal/index.vue"),
          },
          {
            path: "edit",
            meta: {
              title:'添加套餐',
              titleEdit:'编辑套餐',
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/meal/edit/index.vue"),
          },
        ],
      },
      {
        path: "warehouse",
        name: "warehouse",
        meta: {
          title: "",
          cache: false,
          roles: [1],
        },
        redirect: "/warehouse/",
        component: Rview,
        children: [
          {
            path: "out",
            meta: {
              title: "出库管理",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/warehouse/out/index.vue"),
          },
          {
            path: "put",
            meta: {
              title: "入库管理",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/warehouse/put/index.vue"),
          },

          {
            path: "approach",
            meta: {
              title: "进场管理",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/warehouse/approach/index.vue"),
          },
          {
            path: "quality",
            meta: {
              title: "质检管理",
              cache: false,
              roles: [1],
              // without_auth: true
            },
            component: () => import("@/views/warehouse/quality/index.vue"),
          },
          
        ],
      },
      
    ],
  },
];

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
